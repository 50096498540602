import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    sendCssToMongo(loanId) {
        return HttpWrapper.post(`${endpoints.COMMERCIAL_SETTLEMENT_STATEMENT}/sendcsstomongo`, {loanId});
    },

    updateCSS(data) {
        return HttpWrapper.put(`${endpoints.COMMERCIAL_SETTLEMENT_STATEMENT}/${data.data.loan.loanID}`, data );
    },

    updateCssSettings(loanID, settings){
        return HttpWrapper.put(`${endpoints.COMMERCIAL_SETTLEMENT_STATEMENT}/cssSettings/${loanID}`, settings);
    },

    updateCSSandCSSSettings(data){
         return HttpWrapper.put(`${endpoints.COMMERCIAL_SETTLEMENT_STATEMENT}/cssSaveAndSettingsSave/${data.data.loan.loanID}`, data);
    },

    updateAllCSSForOrder(ordersId) {
        return HttpWrapper.put(`${endpoints.COMMERCIAL_SETTLEMENT_STATEMENT}/updateAll/${ordersId}`);
    },
        
    initializeCSSCalculatorLines(loanId, calculatorType, calculatorLineNumber) {
        return HttpWrapper.put(`${endpoints.COMMERCIAL_SETTLEMENT_STATEMENT}/calculators/${loanId}`, {
            calculatorType: calculatorType,
            calculatorLineNumber: calculatorLineNumber,
        });
    },

    OnUpdateRCWhenDebitPurchaserEdited(loanId, realtorCommissionPayeeID,debitPurchaser){
        return HttpWrapper.put(`${endpoints.COMMERCIAL_SETTLEMENT_STATEMENT}/realtorCommissionsUpdate/${loanId}`,
        {
            realtorCommissionPayeeID: realtorCommissionPayeeID, 
            debitPurchaser: debitPurchaser
        });

    }
};
